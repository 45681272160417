export default {
  "brandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualRag"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "visualSimulator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual Simulator"])},
  "hairStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair Style"])},
  "hairColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair Color"])},
  "clothesColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothes Color"])},
  "searchItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Item"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
  "mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mid"])},
  "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
  "arrowHelperBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the arrows"])},
  "arrowHelperAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to navigate through visuals"])},
  "garment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garment"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "costume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costume"])},
  "outfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outfit"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "resetCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character"])},
  "aboutProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Project"])},
  "aboutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualSimulator is a plataform that allow you and your friends simulate a ragnarok character outifit, this project is based on 'Brazil Ragnarok Online' visual system. You can find hundred of item to try until you find the most beautiful outifit. VisualSimulator is weekly updated according to bRO"])},
  "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "warningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualSimulator is not endorsed by or affiliated with Gravity / Warp Portal. Ragnarok Online® is ©2002-2022 Gravity Co., Ltd. & Lee Myoungjin. Game graphics (e.g. item/sprite graphics) © 2002-2022 Gravity Corp. & Lee Myoungjin."])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributors"])},
  "socialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
  "mount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mount"])},
  "regularMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Mount"])},
  "cashMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Mount"])}
}
export default {
  "brandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualRag"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
  "visualSimulator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulador de Visuais"])},
  "hairStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estilo do Cabelo"])},
  "hairColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cor do Cabelo"])},
  "clothesColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cor da Roupa"])},
  "searchItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar Item"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topo"])},
  "mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meio"])},
  "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixo"])},
  "arrowHelperBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilize as setas"])},
  "arrowHelperAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para navegar pelos visuais"])},
  "garment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capa"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
  "costume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costume"])},
  "outfit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roupa Alternativa"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetar"])},
  "resetCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personagem"])},
  "aboutProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre o Projeto"])},
  "aboutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VisualSimulator é uma plataforma que permite que você e seus amigos simulem uma roupa de personagem do Ragnarok, este projeto é baseado no sistema visual do 'Brasil Ragnarok Online'. Você pode tem centenas de itens para experimentar até encontrar a roupa mais bonita. VisualSimulator é atualizado semanalmente de acordo com o bRO"])},
  "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnologias"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso"])},
  "warningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O VisualSimulator não é endossado ou afiliado a Gravity / Warp Portal. Ragnarok Online® ©2002-2022 é uma marca de Gravity Co., Ltd. & Lee Myoungjin. Gráficos do jogo (por exemplo, gráficos de itens/sprites) © 2002-2022 Gravity Corp. & Lee Myoungjin."])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribuidores"])},
  "socialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midias Sociais"])},
  "mount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaria"])},
  "regularMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaria de Classe"])},
  "cashMount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaria de Cash"])}
}